import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Typography = makeShortcode("Typography");
const Grid = makeShortcode("Grid");
const SaveTextField = makeShortcode("SaveTextField");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "if-a-loved-dies-during-the-pandemic"
    }}>{`If a loved dies during the pandemic`}</h2>
    <p>{`Some young people may experience the devastation of losing a loved one during the pandemic. This may even be the first time they experience such a loss. Losing someone during this time can be more complex than usual, particularly if the person dies from COVID-19. Grief can be complicated by the local restrictions, such as not being able to visit the person before they die, limits on funeral attendance, and not being able to spend time with family and friends while grieving. Before supporting your teen, it might be helpful to think about how you have coped with loss in the past.`}</p>
    <Typography color="primary" mdxType="Typography">
  <b>Put yourself in their shoes.</b>
    </Typography>
    <p>{`Think back to your own teenage years. If you can, recall a time when you experienced a loss. It doesn’t need to involve death of a person; grief can be due to loss of a pet, a relationship, or an opportunity. Can you remember how you felt and how it impacted on you? How did your family support you `}{`—`}{` what helped and what didn’t? How would you have liked the adults around you to have responded?`}</p>
    <Typography color="primary" mdxType="Typography">If you like, you might find it helpful to write down your thoughts here:</Typography>
    <br />
    <Grid container justify="center" spacing={2} mdxType="Grid">
  <SaveTextField id="pandemicLovedOneDeath" variant="outlined" fullWidth={true} multiline={true} label="Insert text here" mdxType="SaveTextField" />
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      